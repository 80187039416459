<template>
  <div>
    <div class="chat_list">
      <div class="chat_item">
        <img src="@/assets/ava_default.png" />
        <div class="chat_content">您好，我是您的助理，欢迎来到测评。</div>
      </div>
      <div class="chat_item">
        <img src="@/assets/ava_default.png" />
        <div class="chat_content">阅读用户隐私协议。</div>
      </div>
    </div>
    <div class="model">
      <div class="title">隐私协议</div>
      <div class="content">
        在此特别提醒您（用户）在注册成为用户之前，请认真阅读本《用户协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。本协议约定苏州睿酷医疗科技有限责任公司（以下简称“苏州睿酷”）与用户之间关于“心理和疼痛量表评估软件”服务软件（以下简称“服务”）的权利义务。
 “用户”是指注册、登录、使用本服务的个人和机构。本协议可由苏州睿酷随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本APP/小程序/网页中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用苏州睿酷提供的服务，用户继续使用服务将被视为接受修改后的协议。
一、账号注册  1、用户在使用本服务前需要注册一个“心理和疼痛量表评估软件”账号。“心理和疼痛量表评估软件”账号由睿酷公司授权发放。服务可 以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。  2、“心理和疼痛量表评估软件”是由苏州睿酷自主研发的APP/小程序/网页产品，用户注册时应当授权苏州睿酷及使用其个人信息方可成功注册“心理和疼痛量表评估软件”账号。故用户完成注册即表明用户同意服务提取、公开及使用用户的信息。 3、鉴于“心理和疼痛量表评估软件”账号的绑定注册方式，您同意服务在注册时将允许您的信息用于注册。 4、在用户注册及使用本服务时，苏州睿酷需要搜集能识别用户身份的个人信息以便服务可以在必要时联系用户，或为用户提供更好的使用体验。苏州睿酷搜集的信息包括但不限于用户的姓名、性别、年龄、地址、受教育程度等；苏州睿酷同意对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。
 二、用户个人隐私信息保护  1、如果苏州睿酷发现或收到他人举报或投诉用户违反本协议约定的，苏州睿酷有权不经通知随时对相关内容，包括但不限于用户资料等进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁 、设备封禁 、功能封禁的处罚，且通知用户处理结果。 2、因违反用户协议被封禁的用户，可以自行与苏州睿酷联系。其中，被实施功能封禁的用户会在封禁期届满后自动恢复被封禁功能。被封禁用户可提交申诉，苏州睿酷将对申诉进行审查，并自行合理判断决定是否变更处罚措施。 3、用户理解并同意，苏州睿酷有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。 4、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿苏州睿酷与合作公司、关联公司，并使之免受损害。
      </div>
      <div class="bottom">
        <div class="cancel" @click="cancel">不想继续</div>
        <div class="confirm" @click="confirm">知晓，并同意</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YinSi",
  data() {
    return {};
  },
  methods: {
    //取消就进入问卷选择页面
    cancel() {
      let that = this;
      this.$confirm("确定要取消吗?", "提示", {
        confirmButtonText: "确定取消",
        cancelButtonText: "再想一想",
        type: "warning",
        buttonSize: "large",
      })
        .then(() => {
          that.$emit("cancel");
        })
        .catch(() => {});
    },
    //确认，就下一步
    confirm() {
      this.$emit("continue");
    },
  },
};
</script>

<style lang="scss" scoped>
.chat_list {
  padding: 34px;
}
.chat_item {
  display: flex;
  align-items: center;
  margin: 12px 0px;
}
.chat_content {
  width: 624px;
  height: 96px;
  padding-right: 29px;
  padding-left: 45px;
  margin-left: 12px;
  font-size: 30px;
  font-weight: 400;
  line-height: 96px;
  color: #606266;
  background: #ffffff;
  border: 1px solid rgba(221, 221, 221, 0.91);
  border-radius: 53px 48px 48px 0px;
  box-shadow: 0px 0px 0px 0px rgba(184, 184, 184, 0.1);
}
.model {
  width: 100%;
  padding-top: 41px;
  background: #ffffff;
}
.title {
  height: 46px;
  font-size: 48px;
  font-weight: bold;
  line-height: 40px;
  color: #101010;
  text-align: center;
}
.content {
  width: 860px;
  height: 444px;
  padding: 29px 37px;
  margin: 0px auto;
  margin-top: 40px;
  overflow: scroll;

  font-size: 28px;
  font-weight: 400;
  line-height: 45px;
  color: #606266;
  background: #f6f8f9;
  border: 1px solid rgba(221, 221, 221, 0.91);
  border-radius: 10px;
}
.bottom {
  display: flex;
  justify-content: center;
  padding-top: 59px;
  padding-bottom: 59px;
}

.cancel {
  width: 314px;
  height: 102px;
  margin: 0px 55px;
  font-size: 36px;
  font-weight: 400;
  line-height: 102px;
  color: #878787;
  text-align: center;
  cursor: pointer;
  background: #edf3f5;
  border-radius: 51px;
}
.confirm {
  width: 314px;
  height: 102px;
  margin: 0px 55px;
  font-size: 36px;
  font-weight: 400;
  line-height: 102px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background: #078599;
  border-radius: 51px;
}
</style>
